import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import NewsletterCompact from '@widgets/NewsletterCompact'
import Social from '@widgets/Social'
import { useBlogTags, useBlogCategories,getReadTimeFromBlog } from '@helpers-blog'
import { attachExcerptToEvent, getStaticPageSeoDetails } from '@utils-blog'
import { readingTime } from 'reading-time-estimator'

const Blogs = props => {
  let categories = useBlogCategories()
  categories=categories.filter(item=>item.type==='Blog')
  const tags = useBlogTags()
  const paginatedPosts = props.data.paginated
  attachExcerptToEvent(paginatedPosts)
  let seoData = getStaticPageSeoDetails('shared.blogs')
  const blogsSeoData = {
    ...seoData,
    Blogs: { ...seoData?.Blogs, keywords: seoData?.Blogs?.keywords?.split('\n') }
  }

  paginatedPosts.nodes.forEach(ele => {
    ele.bodyContent = getReadTimeFromBlog(ele.title)
    if (ele.bodyContent && ele.bodyContent.body) {
      const text = ele.bodyContent.body.data.body.replace(/(<([^>]+)>)/gi, '')
      ele.readingTime = readingTime(text, 220)
    }
     })
     
  // attachCategoriesToEvent(paginatedPosts)
  const { pageContext: { services = {}, basePath } = {} } = props
  return (
    <Layout {...props}>
      <Seo
        title={blogsSeoData?.Blogs?.metaTitle}
        description={blogsSeoData?.Blogs?.metaDescription}
        keywords={blogsSeoData?.Blogs?.keywords}
        thumbnail={blogsSeoData?.Blogs?.metaImage}
      />
      <Divider />
      <Stack>
        <Main>
          <CardList
            variant={['horizontal-md', 'vertical']}
            title='Blogs'
            callFrom='Blog'
            nodes={paginatedPosts.nodes}
            columns={[1, 2, 3, 3]}
          />
        </Main>
        <Sidebar>
          <Sticky>
          <Categories categories={categories} />
          <Divider />
          {/* <Tags tags={tags} />
          <Divider />
          <Social />
            {services.mailchimp && (
              <>
                <Divider />
                <NewsletterCompact />
              </>
            )} */}
          </Sticky>
        </Sidebar>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} prefix={'blog'} />
      </PreFooter>
    </Layout>
  )
}
export default Blogs
