import { graphql } from 'gatsby'
import Container from '../containers/Blogs'

export default Container

export const pageQuery = graphql`
  query PostsBlogsQuery($paginatePostsPage: Boolean!, $skip: Int, $limit: Int) {
    paginated: allBlog(
      filter: { private: { ne: true } }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) @include(if: $paginatePostsPage) {
      nodes {
        id
        title
        slug
        excerpt
        timeToRead
        featured
        updatedAt(formatString: "MMMM DD, YYYY")
        date(formatString: "MMMM DD, YYYY")
        category {
          ...BlogCategory
        }
        author {
          id
          name
          slug
          title
          description
          skills
          social {
            name
            url
          }
          thumbnail {
            __typename
            ... on ImageSharp {
              ImageSharp_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 75
              )
              ImageSharp_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 80
              )
            }
            ... on ContentfulAsset {
              ContentfulAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 75
              )
              ContentfulAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                cropFocus: TOP
                resizingBehavior: THUMB
                quality: 80
              )
            }
            ... on SanityImageAsset {
              SanityImageAsset_small: gatsbyImageData(
                width: 48
                height: 48
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
              SanityImageAsset_regular: gatsbyImageData(
                width: 150
                height: 150
                layout: FIXED
                placeholder: NONE
                fit: CLIP
              )
            }
          }
        }

        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }

      pageInfo {
        pageCount
        hasNextPage
        currentPage
        hasPreviousPage
      }
      totalCount
    }
  }
`
