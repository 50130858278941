import { useStaticQuery, graphql } from 'gatsby'

// :::: GET Author Description from StrapiAuthors ::::
export const allStrapiCategory = (name) => {
  const { allStrapiCategory } = useStaticQuery(allStrapiAuthorDes)
    const allCategory = allStrapiCategory.nodes.filter(
      node => node.name === name
    )

    return allCategory[0].icons
}

const allStrapiAuthorDes = graphql`
  query {
    allStrapiCategory {
      nodes {
        icons
        name
      }
    }
  }
`